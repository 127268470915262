// @nebular theming framework
@import '~@nebular/theme/styles/theming';
// @nebular out of the box themes
@import '~@nebular/theme/styles/themes/default';
@import '~@nebular/theme/styles/themes/dark';

// Specify which themes are enabled (empty to enable all)
// $nb-enabled-themes: (default, dark);

/*
 * Import media mixins into global style sheet.
 * Not sure why this works here but not in styles.scss.
 */
@import 'media.mixin';

// Define the themes
$nb-themes: nb-register-theme(
    (
        // New theme colours
        color-secondary-default: #0c8ac7,
        color-secondary-disabled: #91c9e5,
        color-secondary-active: #066fa2,
        color-primary-hightlightbox-bg: #fff4f2,
        color-primary-hightlightbox-base-text: text-primary-color,
        color-primary-100: #fff4f2,
        color-primary-200: #ffdfd9,
        // color-primary-disabled
        color-primary-300: #ffbe87,
        // color-primary-hover/active
        color-primary-400: #eb7d21,
        // color-primary-default
        color-primary-500: #fe9339,
        color-primary-600: #d93f27,
        color-primary-700: #b52b1b,
        color-primary-800: #911a11,
        color-primary-900: #570906,
        color-success-100: #f0fffb,
        color-success-200: #ccfcf0,
        // color-success-disabled
        color-success-300: #98ddb5,
        // color-success-hover/active
        color-success-400: #1ba863,
        // color-success-default
        color-success-500: #6acf9e,
        color-success-600: #009975,
        color-success-700: #008066,
        color-success-800: #006957,
        color-success-900: #003d35,
        color-basic-100: white,
        color-basic-200: #f7f9fc,
        color-basic-300: #edf1f7,
        color-basic-400: #dfe4f0,
        color-basic-500: #c5ccdb,
        color-basic-600: #9fa9bd,
        color-basic-700: #424d69,
        color-basic-800: #2a344f,
        color-basic-900: #202840,
        color-basic-1000: #171d33,
        color-basic-1100: #131729,
        color-info-100: #d6f6fd,
        color-info-200: #ade9fc,
        // color-info-disabled
        color-info-300: #7dbced,
        // color-info-hover/active
        color-info-400: #177ecd,
        // color-info-default
        color-info-500: #5eace9,
        color-info-600: #257ac6,
        color-info-700: #195ba6,
        color-info-800: #104085,
        color-info-900: #092d6e,
        color-warning-100: #fdf9cf,
        color-warning-200: #fcf39f,
        // color-warning-disabled
        color-warning-300: #ffd07e,
        // color-warning-hover/active
        color-warning-400: #e49409,
        // color-warning-default
        color-warning-500: #f9bf4c,
        color-warning-600: #c5a80d,
        color-warning-700: #a58a09,
        color-warning-800: #856d06,
        color-warning-900: #6e5803,
        color-danger-100: #fedccb,
        color-danger-200: #fdb298,
        // color-danger-disabled
        color-danger-300: #ff917b,
        // color-danger-hover/active
        color-danger-400: #e1492b,
        // color-danger-default
        color-danger-500: #f0735b,
        color-danger-600: #cd0011,
        color-danger-700: #ac001d,
        color-danger-800: #8a0024,
        color-danger-900: #720027,
        // Custom variables:
        engin-logo-header: url('../../../assets/logo/logo_engin_colour.svg') no-repeat center,
        background-basic-color-5: color-basic-100,
        background-basic-color-6: color-basic-200,
        background-basic-color-7: color-basic-100,
        border-color-1: color-basic-300,
        border-color-2: color-text-100,
        //text colors
        color-text-extra: #a6aebd,
        color-text-100: #dde1eb,
        color-text-200: #c8cedb,
        color-text-300: #8992a3,
        color-text-400: #6e7481,
        color-text-500: #0d1c2e,
        color-text-600: #131729,
        text-color-1: color-text-500,
        text-color-2: color-text-500,
        text-color-3: color-text-300,
        //Layout
        layout-window-mode-max-width: '2560px',
        header-padding: 0,
        header-height: 64px,
        header-shadow: 0 4px 16px 0 rgba(0, 13, 64, 0.05),
        // don't working ?????
        footer-height: 64px,
        footer-shadow: none,
        footer-text-font-size: 10px,
        footer-text-line-height: 16px,
        footer-text-color: text-control-color,
        footer-background-color: color-basic-1000,
        footer-padding: 0 10px,
        //Sidebar
        sidebar-background-color: color-basic-1100,
        sidebar-width: 220px,
        sidebar-width-compact: 64px,
        sidebar-padding: 0,
        sidebar-shadow: none,
        sidebar-text-color: color-text-200,
        //Popover
        popover-background-color: color-basic-100,
        popover-border-color: none,
        popover-border-radius: 4px,
        popover-arrow-size: 0.6875rem,
        popover-padding: 2rem,
        popover-text-color: text-color-2,
        //Progress-bar
        progress-bar-border-radius: 4px,
        progress-bar-tiny-height: 8px,
        //Accordion
        accordion-border-radius: none,
        accordion-shadow: none,
        accordion-header-border-style: solid,
        accordion-header-border-width: 1px,
        //Checkbox and Radio
        checkbox-text-font-size: 0.8125rem,
        checkbox-text-font-weight: 500,
        checkbox-text-line-height: 1.125rem,
        checkbox-text-font-family: Roboto 'Helvetica Neue' sans-serif,
        checkbox-basic-text-color: text-color-1,
        checkbox-basic-background-color: color-text-100,
        checkbox-basic-border-color: color-text-extra,
        checkbox-basic-disabled-background-color: color-basic-300,
        checkbox-basic-disabled-border-color: color-text-100,
        checkbox-basic-hover-background-color: color-basic-100,
        checkbox-basic-hover-border-color: color-primary-400,
        checkbox-basic-focus-checked-background-color: color-primary-400,
        checkbox-basic-focus-checked-border-color: color-primary-400,
        checkbox-basic-checked-checkmark-color: color-basic-100,
        radio-text-font-size: 0.8125rem,
        radio-text-font-weight: 500,
        radio-text-line-height: 1.125rem,
        radio-text-font-family: Roboto 'Helvetica Neue' sans-serif,
        radio-basic-text-color: text-color-1,
        radio-basic-background-color: color-basic-200,
        radio-basic-border-color: color-text-extra,
        radio-basic-inner-circle-color: color-primary-400,
        radio-basic-disabled-background-color: color-basic-300,
        radio-basic-disabled-border-color: color-basic-500,
        radio-basic-disabled-checked-border-color: color-primary-300,
        radio-basic-disabled-checked-inner-circle-color: color-primary-300,
        radio-basic-hover-background-color: color-basic-100,
        radio-basic-hover-border-color: color-primary-400,
        radio-basic-focus-inner-circle-color: color-primary-400,
        radio-basic-focus-border-color: color-primary-400,
        radio-basic-active-border-color: color-primary-400,
        radio-basic-active-inner-circle-color: color-primary-400,
        //Select
        select-outline-basic-text-color: text-color-2,
        select-outline-basic-placeholder-text-color: color-text-200,
        select-outline-basic-background-color: background-basic-color-6,
        select-outline-basic-border-color: #e3e6ee,
        select-outline-basic-focus-background-color: color-basic-100,
        select-outline-basic-focus-border-color: color-primary-400,
        select-outline-basic-hover-background-color: background-basic-color-6,
        select-outline-basic-hover-border-color: color-text-extra,
        select-outline-basic-disabled-background-color: background-basic-color-6,
        select-outline-basic-disabled-border-color: color-basic-300,
        select-outline-basic-disabled-text-color: text-color-2,
        //Input
        input-basic-text-color: text-color-2,
        input-basic-placeholder-text-color: color-text-200,
        input-basic-background-color: background-basic-color-6,
        input-basic-border-color: #e3e6ee,
        input-basic-hover-background-color: background-basic-color-6,
        input-basic-hover-border-color: color-text-extra,
        input-basic-focus-background-color: color-basic-100,
        input-basic-focus-border-color: color-primary-400,
        input-basic-disabled-background-color: background-basic-color-6,
        input-basic-disabled-border-color: color-basic-300,
        input-basic-disabled-text-color: text-color-2,
        //tooltips
        tooltip-background-color: color-basic-100,
        tooltip-text-color: text-color-2,
        //Buttons
        button-filled-basic-background-color: color-text-100,
        button-filled-basic-border-color: color-text-100,
        button-filled-basic-text-color: text-color-1,
        button-filled-basic-focus-background-color: color-basic-500,
        button-filled-basic-focus-border-color: rgba(197, 204, 219, 0.33),
        button-filled-basic-hover-background-color: color-basic-500,
        button-filled-basic-hover-border-color: color-basic-500,
        button-filled-basic-active-background-color: color-basic-500,
        button-filled-basic-active-border-color: color-basic-500,
        button-filled-basic-disabled-background-color: #e3e6ee,
        button-filled-basic-disabled-border-color: #e3e6ee,
        button-filled-basic-disabled-text-color: color-text-300,
        button-filled-primary-background-color: color-secondary-default,
        button-filled-primary-border-color: color-secondary-default,
        button-filled-primary-text-color: color-basic-100,
        button-filled-primary-focus-background-color: color-secondary-active,
        button-filled-primary-focus-border-color: rgba(6, 111, 162, 0.33),
        button-filled-primary-hover-background-color: color-secondary-active,
        button-filled-primary-hover-border-color: color-secondary-active,
        button-filled-primary-active-background-color: color-secondary-active,
        button-filled-primary-active-border-color: color-secondary-active,
        button-filled-primary-disabled-background-color: color-secondary-disabled,
        button-filled-primary-disabled-border-color: color-secondary-disabled,
        button-filled-primary-disabled-text-color: color-basic-100,
        //Card
        card-background-color: background-basic-color-5,
        //Toast
        toastr-text-font-family: Roboto 'Helvetica Neue' sans-serif,
        toastr-title-text-font-family: Roboto 'Helvetica Neue' sans-serif,
        toastr-success-background-color: color-success-400,
        toastr-success-border-color: color-success-400,
        toastr-icon-success-background-color: transparent,
        toastr-icon-success-color: color-basic-100,
        toastr-warning-background-color: color-warning-400,
        toastr-warning-border-color: color-warning-400,
        toastr-icon-warning-background-color: transparent,
        toastr-icon-warning-color: color-basic-100,
        toastr-danger-background-color: color-danger-400,
        toastr-danger-border-color: color-danger-400,
        toastr-icon-danger-background-color: transparent,
        toastr-icon-danger-color: color-basic-100,
        toastr-info-background-color: color-info-400,
        toastr-info-border-color: color-info-400,
        toastr-icon-info-background-color: transparent,
        toastr-icon-info-color: color-basic-100,
        toastr-destroyable-success-hover-background-color: #167d4a,
        toastr-destroyable-warning-hover-background-color: #c27e08,
        toastr-destroyable-danger-hover-background-color: #c6361b,
        toastr-destroyable-info-hover-background-color: #156db1,
        toastr-destroyable-success-hover-border-color: #167d4a,
        toastr-destroyable-warning-hover-border-color: #c27e08,
        toastr-destroyable-danger-hover-border-color: #c6361b,
        toastr-destroyable-info-hover-border-color: #156db1,
        // custom variables
        table-background-color: background-basic-color-1,
        table-pager-not-selected-background-color: background-basic-color-2,
        table-pager-selected-background-color: background-basic-color-3,
        smart-table-bg-even: background-basic-color-2,
        smart-table-header-font-weight: text-heading-1-font-weight,
        smart-table-font-weight: text-subtitle-font-weight,
        smart-table-font-size: text-subtitle-2-font-size,
        table-action-btn-background-color: color-basic-300,
        table-action-btn-background-color-hover: color-text-100,
        table-action-btn-color: color-text-400,
        table-action-btn-color-hover: color-basic-1000,
        table-pagination-bg: color-basic-200,
        table-pagination-border: #e3e6ee,
        empty-chart-color: #ededed,
        // Custom colours for map
        color-gradient-category-1: #84db2c,
        color-gradient-category-2: #b7db00,
        color-gradient-category-3: #ffd500,
        color-gradient-category-4: #ffaa00,
        color-gradient-category-5: #ff5e29,
        color-gradient-category-empty: #9fa9bd,
        color-gradient-numeric-1: #1996d2,
        color-gradient-numeric-2: #69b9df,
        color-gradient-numeric-3: #ffc776,
        color-gradient-numeric-4: #f384ab,
        color-gradient-numeric-5: #eb4e76,
        //Support colors
        color-chart-map-1: #ff708d,
        color-chart-map-2: #ffb660,
        color-chart-map-3: #fad36d,
        color-chart-map-4: #a0de92,
        color-chart-map-5: #00b383,
        color-chart-map-6: #a6aebd,
        color-chart-map-7: #776eff,
        color-chart-map-8: #ffabab,
        color-chart-map-9: #e3e88b,
        color-chart-map-10: #c853ff,
        color-chart-map-11: #579fe6,
        //general
        general-background-color: #ffffff,
        general-box-hover-color: #edf1f7,
        general-font-color: #0d1c2e,
        general-icon-color: #0d1c2e,
        general-border-color: #edf1f7,
        general-small-box-color: #edf1f7,
        general-input-box-color: #edf1f7,
        general-button-color: #ffffff,
        general-placeholder-color: #8992a3,
        general-select-title-background-color: #f7f9fc,
        general-select-background-color: #ffffff,
        general-select-hover-color: #ffffff,
        general-scroll-bar-color: #dfe4f0
    ),
    default,
    default
);

$nb-themes: nb-register-theme(
    (
        color-secondary-default: #0c8ac7,
        color-secondary-disabled: #91c9e5,
        color-secondary-active: #066fa2,
        color-primary-hightlightbox-bg: color-basic-900,
        color-primary-hightlightbox-base-text: #fff4f2,
        color-primary-100: #fff4f2,
        color-primary-200: #ffdfd9,
        color-primary-300: #ffbe87,
        // color-primary-disabled
        color-primary-400: #eb7d21,
        // color-primary-hover/active
        color-primary-500: #fe9339,
        // color-primary-default
        color-primary-600: #d93f27,
        color-primary-700: #b52b1b,
        color-primary-800: #911a11,
        color-primary-900: #570906,
        color-success-100: #f0fffb,
        color-success-200: #ccfcf0,
        color-success-300: #98ddb5,
        // color-success-disabled
        color-success-400: #1ba863,
        // color-success-hover/active
        color-success-500: #6acf9e,
        // color-success-default
        color-success-600: #009975,
        color-success-700: #008066,
        color-success-800: #006957,
        color-success-900: #003d35,
        color-basic-100: white,
        color-basic-200: #f7f9fc,
        color-basic-300: #edf1f7,
        color-basic-400: #dfe4f0,
        color-basic-500: #c5ccdb,
        color-basic-600: #9fa9bd,
        color-basic-700: #424d69,
        color-basic-800: #2a344f,
        color-basic-900: #202840,
        color-basic-1000: #171d33,
        color-basic-1100: #131729,
        color-info-100: #d6f6fd,
        color-info-200: #ade9fc,
        color-info-300: #7dbced,
        // color-info-disabled
        color-info-400: #177ecd,
        // color-info-hover/active
        color-info-500: #5eace9,
        // color-info-default
        color-info-600: #257ac6,
        color-info-700: #195ba6,
        color-info-800: #104085,
        color-info-900: #092d6e,
        color-warning-100: #fdf9cf,
        color-warning-300: #ffd07e,
        // color-warning-disabled
        color-warning-200: #fcf39f,
        color-warning-400: #e49409,
        // color-warning-hover/active
        color-warning-500: #f9bf4c,
        // color-warning-default
        color-warning-600: #c5a80d,
        color-warning-700: #a58a09,
        color-warning-800: #856d06,
        color-warning-900: #6e5803,
        color-danger-100: #fedccb,
        color-danger-200: #fdb298,
        color-danger-300: #ff917b,
        // color-danger-disabled
        color-danger-400: #e1492b,
        // color-danger-hover/active
        color-danger-500: #f0735b,
        // color-danger-default
        color-danger-600: #cd0011,
        color-danger-700: #ac001d,
        color-danger-800: #8a0024,
        color-danger-900: #720027,
        // Custom variables
        engin-logo-header: url('../../../assets/logo/logo_engin_white.svg') no-repeat center,
        background-basic-color-5: color-basic-900,
        background-basic-color-6: color-basic-1000,
        background-basic-color-7: color-basic-800,
        border-color-1: color-basic-1100,
        border-color-2: color-basic-1000,
        //text colors
        color-text-extra: #a6aebd,
        color-text-100: #dde1eb,
        color-text-200: #c8cedb,
        color-text-300: #8992a3,
        color-text-400: #6e7481,
        color-text-500: #0d1c2e,
        color-text-600: #131729,
        text-color-1: color-text-100,
        text-color-2: color-basic-100,
        text-color-3: color-text-100,
        //Layout
        layout-window-mode-max-width: '2560px',
        header-padding: 0,
        header-height: 64px,
        header-shadow: none,
        header-background-color: background-basic-color-1,
        // don't working
        footer-height: 64px,
        footer-shadow: none,
        footer-text-font-size: 10px,
        footer-text-line-height: 16px,
        footer-background-color: background-basic-color-3,
        footer-divider-width: 0,
        footer-padding: 0 10px,
        //Sidebar
        sidebar-background-color: color-basic-1100,
        sidebar-width: 220px,
        sidebar-width-compact: 64px,
        sidebar-padding: 0,
        sidebar-shadow: none,
        sidebar-text-color: color-text-200,
        //Popover
        popover-background-color: color-basic-1000,
        popover-border-color: none,
        popover-border-radius: 4px,
        popover-arrow-size: 0.6875rem,
        popover-padding: 0,
        popover-text-color: text-color-2,
        //Progress bar
        progress-bar-border-radius: 4px,
        progress-bar-tiny-height: 8px,
        //Accordion
        accordion-border-radius: none,
        accordion-shadow: none,
        accordion-header-border-style: solid,
        accordion-header-border-width: 1px,
        //Checkbox and radio
        checkbox-text-font-size: 0.8125rem,
        checkbox-text-font-weight: 500,
        checkbox-text-line-height: 1.125rem,
        checkbox-text-font-family: Roboto 'Helvetica Neue' sans-serif,
        checkbox-basic-text-color: text-color-1,
        checkbox-basic-background-color: color-basic-800,
        checkbox-basic-border-color: color-basic-1000,
        checkbox-basic-disabled-background-color: color-basic-1000,
        checkbox-basic-disabled-border-color: color-basic-800,
        checkbox-basic-hover-background-color: color-basic-800,
        checkbox-basic-hover-border-color: color-primary-400,
        checkbox-basic-focus-checked-background-color: color-primary-400,
        checkbox-basic-focus-checked-border-color: color-primary-400,
        checkbox-basic-checked-checkmark-color: color-basic-1000,
        radio-text-font-size: 0.8125rem,
        radio-text-font-weight: 500,
        radio-text-line-height: 1.125rem,
        radio-text-font-family: Roboto 'Helvetica Neue' sans-serif,
        radio-basic-text-color: text-color-1,
        radio-basic-background-color: color-basic-900,
        radio-basic-border-color: color-text-extra,
        radio-basic-inner-circle-color: color-primary-400,
        radio-basic-disabled-background-color: color-basic-1000,
        radio-basic-disabled-border-color: color-basic-800,
        radio-basic-disabled-checked-border-color: color-primary-300,
        radio-basic-disabled-checked-inner-circle-color: color-primary-300,
        radio-basic-hover-background-color: color-basic-800,
        radio-basic-hover-border-color: color-primary-400,
        radio-basic-focus-inner-circle-color: color-primary-400,
        radio-basic-focus-border-color: color-primary-400,
        radio-basic-active-border-color: color-primary-400,
        radio-basic-active-inner-circle-color: color-primary-400,
        //Select
        select-outline-basic-text-color: text-color-2,
        select-outline-basic-placeholder-text-color: color-basic-700,
        select-outline-basic-background-color: background-basic-color-6,
        select-outline-basic-border-color: color-basic-1100,
        select-outline-basic-focus-background-color: background-basic-color-6,
        select-outline-basic-focus-border-color: color-primary-400,
        select-outline-basic-hover-background-color: color-basic-900,
        select-outline-basic-hover-border-color: color-primary-400,
        select-outline-basic-disabled-background-color: background-basic-color-6,
        select-outline-basic-disabled-border-color: background-basic-color-6,
        select-outline-basic-disabled-text-color: text-color-2,
        //Input
        input-basic-text-color: text-color-2,
        input-basic-placeholder-text-color: color-basic-700,
        input-basic-background-color: background-basic-color-6,
        input-basic-border-color: color-basic-1100,
        input-basic-focus-background-color: background-basic-color-6,
        input-basic-focus-border-color: color-primary-400,
        input-basic-hover-background-color: color-basic-900,
        input-basic-hover-border-color: color-primary-400,
        input-basic-disabled-background-color: background-basic-color-6,
        input-basic-disabled-border-color: background-basic-color-6,
        input-basic-disabled-text-color: text-color-2,
        // tooltips
        tooltip-background-color: color-basic-1000,
        tooltip-text-color: text-color-2,
        //Buttons
        button-filled-basic-background-color: color-basic-700,
        button-filled-basic-border-color: color-basic-700,
        button-filled-basic-text-color: text-color-1,
        button-filled-basic-focus-background-color: color-basic-800,
        button-filled-basic-focus-border-color: rgba(42, 52, 79, 0.33),
        button-filled-basic-hover-background-color: color-basic-800,
        button-filled-basic-hover-border-color: color-basic-800,
        button-filled-basic-active-background-color: color-basic-800,
        button-filled-basic-active-border-color: color-basic-800,
        button-filled-basic-disabled-background-color: color-basic-700,
        button-filled-basic-disabled-border-color: color-basic-700,
        button-filled-basic-disabled-text-color: color-text-300,
        button-filled-primary-background-color: color-secondary-default,
        button-filled-primary-border-color: color-secondary-default,
        button-filled-primary-text-color: color-basic-100,
        button-filled-primary-focus-background-color: color-secondary-active,
        button-filled-primary-focus-border-color: rgba(6, 111, 162, 0.33),
        button-filled-primary-hover-background-color: color-secondary-active,
        button-filled-primary-hover-border-color: color-secondary-active,
        button-filled-primary-active-background-color: color-secondary-active,
        button-filled-primary-active-border-color: color-secondary-active,
        button-filled-primary-disabled-background-color: color-secondary-disabled,
        button-filled-primary-disabled-border-color: color-secondary-disabled,
        button-filled-primary-disabled-text-color: color-basic-100,
        //Card
        card-background-color: background-basic-color-5,
        //Toast
        toastr-text-font-family: Roboto 'Helvetica Neue' sans-serif,
        toastr-title-text-font-family: Roboto 'Helvetica Neue' sans-serif,
        toastr-success-background-color: color-success-400,
        toastr-success-border-color: color-success-400,
        toastr-icon-success-background-color: transparent,
        toastr-icon-success-color: color-basic-100,
        toastr-warning-background-color: color-warning-400,
        toastr-warning-border-color: color-warning-400,
        toastr-icon-warning-background-color: transparent,
        toastr-icon-warning-color: color-basic-100,
        toastr-danger-background-color: color-danger-400,
        toastr-danger-border-color: color-danger-400,
        toastr-icon-danger-background-color: transparent,
        toastr-icon-danger-color: color-basic-100,
        toastr-info-background-color: color-info-400,
        toastr-info-border-color: color-info-400,
        toastr-icon-info-background-color: transparent,
        toastr-icon-info-color: color-basic-100,
        toastr-destroyable-success-hover-background-color: #167d4a,
        toastr-destroyable-warning-hover-background-color: #c27e08,
        toastr-destroyable-danger-hover-background-color: #c6361b,
        toastr-destroyable-info-hover-background-color: #156db1,
        toastr-destroyable-success-hover-border-color: #167d4a,
        toastr-destroyable-warning-hover-border-color: #c27e08,
        toastr-destroyable-danger-hover-border-color: #c6361b,
        toastr-destroyable-info-hover-border-color: #156db1,
        // custom variables
        table-background-color: background-basic-color-1,
        table-pager-not-selected-background-color: background-basic-color-2,
        table-pager-selected-background-color: background-basic-color-3,
        smart-table-bg-even: background-basic-color-2,
        smart-table-header-font-weight: text-heading-1-font-weight,
        smart-table-font-weight: text-subtitle-font-weight,
        smart-table-font-size: text-subtitle-2-font-size,
        table-action-btn-background-color: color-basic-700,
        table-action-btn-background-color-hover: color-basic-800,
        table-action-btn-color: text-color-2,
        table-action-btn-color-hover: text-color-2,
        table-pagination-bg: color-basic-800,
        table-pagination-border: color-basic-1100,
        empty-chart-color: #ededed,
        // Custom colours for map
        color-gradient-category-1: #84db2c,
        color-gradient-category-2: #b7db00,
        color-gradient-category-3: #ffd500,
        color-gradient-category-4: #ffaa00,
        color-gradient-category-5: #ff5e29,
        color-gradient-category-empty: #9fa9bd,
        color-gradient-numeric-1: #1996d2,
        color-gradient-numeric-2: #69b9df,
        color-gradient-numeric-3: #ffc776,
        color-gradient-numeric-4: #f384ab,
        color-gradient-numeric-5: #eb4e76,
        //Support colors
        color-chart-map-1: #ff708d,
        color-chart-map-2: #ffb660,
        color-chart-map-3: #fad36d,
        color-chart-map-4: #a0de92,
        color-chart-map-5: #00b383,
        color-chart-map-6: #a6aebd,
        color-chart-map-7: #776eff,
        color-chart-map-8: #ffabab,
        color-chart-map-9: #e3e88b,
        color-chart-map-10: #c853ff,
        color-chart-map-11: #579fe6,
        //general
        general-background-color: #202840,
        general-box-hover-color: #2a344f,
        general-font-color: #dde1eb,
        general-icon-color: #a6aebd,
        general-border-color: #131729,
        general-small-box-color: #131729,
        general-input-box-color: #171d33,
        general-button-color: #2a344f,
        general-placeholder-color: #ffffff,
        general-select-title-background-color: #131729,
        general-select-background-color: #2a344f,
        general-select-hover-color: #202840,
        general-scroll-bar-color: #888,
    ),
    dark,
    dark
);

$nb-themes: nb-register-theme(
    (
        // color-primary-disabled
        color-primary-300: #fad07d,
        // color-primary-hover/active
        color-primary-400: #d9950e,
        // color-primary-default
        color-primary-500: #f7b126,
        //Status-cell-icon-color
        color-basic-600: #9fa9bd,
        // color-success-disabled
        color-success-300: #6688da,
        // color-success-hover/active
        color-success-400: #002e9a,
        // color-success-default
        color-success-500: #0039c1,
        // color-info-disabled
        color-info-300: #b5e7ff,
        // color-info-hover/active
        color-info-400: #56abd3,
        // color-info-default
        color-info-500: #67c4f1,
        // color-warning-disabled
        color-warning-300: #fcf7a4,
        // color-warning-hover/active
        color-warning-400: #dad252,
        // color-warning-default
        color-warning-500: #f0e74c,
        // color-danger-disabled
        color-danger-300: #ff9b94,
        // color-danger-hover/active
        color-danger-400: #cc473d,
        // color-danger-default
        color-danger-500: #ff594c,
        color-secondary-default: #0c8ac7,
        //Toast
        toastr-success-background-color: #0039c1,
        toastr-success-border-color: #0039c1,
        toastr-warning-background-color: #f0e74c,
        toastr-warning-border-color: #f0e74c,
        toastr-danger-background-color: #ff594c,
        toastr-danger-border-color: #ff594c,
        toastr-info-background-color: #67c4f1,
        toastr-info-border-color: #67c4f1,
        toastr-destroyable-success-hover-background-color: #0039c1,
        toastr-destroyable-warning-hover-background-color: #f0e74c,
        toastr-destroyable-danger-hover-background-color: #ff594c,
        toastr-destroyable-info-hover-background-color: #67c4f1,
        toastr-destroyable-success-hover-border-color: #0039c1,
        toastr-destroyable-warning-hover-border-color: #f0e74c,
        toastr-destroyable-danger-hover-border-color: #ff594c,
        toastr-destroyable-info-hover-border-color: #67c4f1,
        // Custom accessibility colours for map
        color-gradient-category-1: #0088ff,
        color-gradient-category-2: #6ebcff,
        color-gradient-category-3: #ffe667,
        color-gradient-category-4: #ffb728,
        color-gradient-category-5: #ff5e29,
        color-gradient-category-empty: #9fa9bd,
        color-gradient-numeric-1: #1996d2,
        color-gradient-numeric-2: #7dc7e0,
        color-gradient-numeric-3: #ffc776,
        color-gradient-numeric-4: #ff9e9e,
        color-gradient-numeric-5: #eb4e76
    ),
    'default_accessibility',
    default
);

$nb-themes: nb-register-theme(
    (
        // color-primary-disabled
        color-primary-300: #fad07d,
        // color-primary-hover/active
        color-primary-400: #d9950e,
        // color-primary-default
        color-primary-500: #f7b126,
        //Status-cell-icon-color
        color-basic-600: #9fa9bd,
        // color-success-disabled
        color-success-300: #6688da,
        // color-success-hover/active
        color-success-400: #002e9a,
        // color-success-default
        color-success-500: #0039c1,
        // color-info-disabled
        color-info-300: #b5e7ff,
        // color-info-hover/active
        color-info-400: #56abd3,
        // color-info-default
        color-info-500: #67c4f1,
        // color-warning-disabled
        color-warning-300: #fcf7a4,
        // color-warning-hover/active
        color-warning-400: #dad252,
        // color-warning-default
        color-warning-500: #f0e74c,
        // color-danger-disabled
        color-danger-300: #ff9b94,
        // color-danger-hover/active
        color-danger-400: #cc473d,
        // color-danger-default
        color-danger-500: #ff594c,
        color-secondary-default: #0c8ac7,
        //Toast
        toastr-success-background-color: #0039c1,
        toastr-success-border-color: #0039c1,
        toastr-warning-background-color: #f0e74c,
        toastr-warning-border-color: #f0e74c,
        toastr-danger-background-color: #ff594c,
        toastr-danger-border-color: #ff594c,
        toastr-info-background-color: #67c4f1,
        toastr-info-border-color: #67c4f1,
        toastr-destroyable-success-hover-background-color: #0039c1,
        toastr-destroyable-warning-hover-background-color: #f0e74c,
        toastr-destroyable-danger-hover-background-color: #ff594c,
        toastr-destroyable-info-hover-background-color: #67c4f1,
        toastr-destroyable-success-hover-border-color: #0039c1,
        toastr-destroyable-warning-hover-border-color: #f0e74c,
        toastr-destroyable-danger-hover-border-color: #ff594c,
        toastr-destroyable-info-hover-border-color: #67c4f1,
        // Custom accessibility colours for map
        color-gradient-category-1: #0088ff,
        color-gradient-category-2: #6ebcff,
        color-gradient-category-3: #ffe667,
        color-gradient-category-4: #ffb728,
        color-gradient-category-5: #ff5e29,
        color-gradient-category-empty: #9fa9bd,
        color-gradient-numeric-1: #1996d2,
        color-gradient-numeric-2: #7dc7e0,
        color-gradient-numeric-3: #ffc776,
        color-gradient-numeric-4: #ff9e9e,
        color-gradient-numeric-5: #eb4e76
    ),
    'dark_accessibility',
    dark
);
