@import 'themes';
@import 'media.mixin.scss';

nb-popover {
    .arrow {
        display: none !important;
    }

    &.nb-overlay-left .arrow {
        display: inline !important;
        right: -23px !important;
    }
}

nb-toastr-container {
    nb-toast {
        display: block !important;

        .icon-container {
            float: left !important;
            min-height: 1rem !important;
            min-width: 1rem !important;
        }

        .title {
            display: block !important;
            margin-bottom: 0.5rem !important;
        }
    }

    &:hover {
        filter: brightness(95%) !important;
    }
}

@mixin ngx-common-theme() {
    * {
        font-family: Roboto, 'Helvetica Neue', sans-serif !important;
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
        -webkit-text-fill-color: nb-theme(input-basic-text-color);
        -webkit-box-shadow: 0 0 0 1000px nb-theme(input-basic-background-color) inset;
        transition: background-color 5000s ease-in-out 0s;

        &:focus {
            -webkit-box-shadow: 0 0 0 1000px nb-theme(input-basic-focus-background-color) inset;
        }

        &:hover {
            -webkit-box-shadow: 0 0 0 1000px nb-theme(input-basic-hover-background-color) inset;
        }
    }
    ::-webkit-scrollbar {
        width: 4px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: nb-theme(general-scroll-bar-color);
        border-radius: 20px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    .height-100 {
        height: 100%;
    }

    .width-100 {
        width: 100%;
    }

    .pointer {
        cursor: pointer;
    }

    .cursor-default {
        cursor: default;
    }

    .pointer-none {
        pointer-events: none;
        opacity: 0.75;
    }

    .display-inline {
        display: inline !important;
    }

    .display-flex {
        display: flex !important;
    }

    .display-none {
        display: none !important;
    }

    .display-block {
        display: block !important;
    }

    .disabled-link {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
    }

    .slider-container {
        position: relative;
        height: 30px;
        margin: 0;
        width: 100%;

        .progress-foreground {
            background-color: nb-theme(color-primary-default);
            height: 4px;
            position: absolute;
            width: 100px;
            left: auto;
            margin-top: 13px;
            z-index: 0;
            max-width: 100%;
        }

        .progress {
            appearance: none;
            width: 100%;
            background: transparent;
            outline: none;
            position: absolute;
            height: 30px;
            overflow: visible;

            @include install-thumb() {
                border-radius: 50%;
                cursor: pointer;
                width: 18px;
                height: 18px;
                background-color: nb-theme(color-basic-default);
                border: 1px solid nb-theme(color-basic-active);
                margin-top: -7px;
                position: relative;
                z-index: 10;
            }

            @include install-track() {
                width: 100%;
                height: 4px;
                cursor: pointer;
                background: nb-theme(color-basic-active);
            }
        }
    }

    .chart-card-footer {
        display: flex;
        flex-direction: column;
        padding: 0 15px;

        .chart-footer-item:not(:last-child) {
            border-bottom: 1px solid nb-theme(border-basic-color-4);
        }

        .chart-footer-item {
            display: flex;
            padding: 0;
            height: 40px;
            justify-content: space-between;
            align-items: center;

            .footer-label-title {
                font-size: 11px;
                font-weight: 600;
                line-height: 16px;

                ::ng-deep nb-icon {
                    padding: 4px;
                    font-size: 20px;
                    color: nb-theme(text-control-color);
                }
            }

            .footer-label-value {
                font-size: 14px;
                font-weight: bold;
                line-height: 24px;
                text-align: right;
            }

            .footer-label-prefix {
                font-size: 13px;
                font-weight: normal;
                line-height: 24px;
                text-align: right;
                color: nb-theme(color-text-300);
            }

            .color-point {
                display: inline-block;
                margin-top: 3px;
                margin-bottom: 3px;
                margin-right: 3px;
                border-radius: 5px;
                width: 20px;
                height: 20px;
            }

            label {
                margin: 0;
            }
        }
    }

    .logo {
        background: url('./../../../assets/logo/logo_engin_white.svg') no-repeat center;
        height: 100%;
    }
    .logo-swi {
        background: url('./../../../assets/logo/logo_swi.svg') no-repeat center;
        height: 100%;
    }
    .logo-mes {
        background: url('./../../../assets/logo/logo_mes.svg') no-repeat center;
        height: 100%;
    }

    .logo-collapsed {
        background: url('./../../../assets/logo/icon_engin.svg') no-repeat center;
        height: 100%;
    }
    .logo-collapsed-swi {
        background: url('./../../../assets/logo/icon_swi.svg') no-repeat center;
        height: 100%;
    }
    .logo-collapsed-mes {
        background: url('./../../../assets/logo/icon_mes.svg') no-repeat center;
        height: 100%;
    }

    nb-radio-group.create-type-radio {
        padding: 4px;
        background-color: nb-theme(background-basic-color-2);
        border-radius: 4px;
        border: 1px solid nb-theme(border-color-2);
        display: flex;
        flex-wrap: nowrap;
        width: fit-content;

        nb-radio {
            border: 1px solid transparent;
            min-width: 5rem;
            padding: 6px;

            &:not(:last-of-type) {
                margin-right: 4px;
            }

            &.active {
                background-color: nb-theme(background-basic-color-7);
                border-radius: 4px;
                border-color: nb-theme(border-color-2);
            }

            label {
                margin: 0 !important;
                padding: 0 !important;
                position: relative;

                span {
                    font-size: 10px;
                    line-height: 20px;
                    font-weight: 700;
                    color: nb-theme(text-color-2);
                }
            }
        }
    }

    .header-logo {
        background: nb-theme(engin-logo-header);
        background-size: cover;
        height: 32px;
        width: 163px;
    }

    .footer-logo {
        background: url('../../../assets/logo/logo_engin_white.svg') no-repeat center;
        height: 32px;
        width: 163px;
    }

    .page-header-loading-container {
        width: 50%;
        height: 64px;
        position: absolute;
        right: 25%;

        nb-spinner {
            background-color: transparent !important;
            z-index: 10 !important;
        }
    }

    nb-card-header {
        padding: 1rem 1.5rem;
        background-color: nb-theme(card-background-color);
        display: flex;

        .primary-header {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 700;
            line-height: 1.25rem;
            color: nb-theme(text-color-1);

            span {
                border-radius: 3px;
                background-color: nb-theme(empty-chart-color);
            }
        }

        .sub-header {
            padding-top: 0.25rem;
            margin: 0;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1rem;
            color: nb-theme(color-text-300);
        }
    }

    // Empty data shell
    .empty-shell {
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
        color: nb-theme(color-basic-500);
        padding: 3rem;

        nb-icon {
            font-size: 3rem;
            margin-bottom: 1rem;
            color: nb-theme(color-basic-500);
        }

        .empty-shell-text {
            font-family: Roboto, 'Helvetica Neue', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 0.75rem;
            line-height: 1rem;
        }
    }

    .page-container {
        display: flex;
        flex-direction: column;
        min-width: 1014px;
        height: 100%;

        .page-header {
            align-items: center;
            display: flex;
            justify-content: space-between;
            //width: calc(100vw - 64px);

            flex: 0 0 auto;
            font-size: 20px;
            font-weight: bold;
            line-height: 28px;
            padding: 0 1.5rem;
            background-color: nb-theme(background-basic-color-2);
            border-bottom: 1px solid nb-theme(border-basic-color-3);
            height: 64px;
            color: nb-theme(text-color-1);

            ngx-icon-box {
                ::ng-deep nb-icon {
                    font-size: 28px;
                }
            }
        }

        .page-content-container {
            display: flex;
            flex-direction: column;
            padding: 16px 24px 24px;
            height: 100%;
        }
    }

    .text-align-start {
        text-align: start;
    }

    .text-align-center {
        text-align: center;
    }

    .text-align-left {
        text-align: left;
    }

    .vertical-align-middle {
        vertical-align: middle;
    }

    .m-auto-0 {
        margin: auto 0;
    }

    .break-word {
        word-break: break-word;
    }

    @each $value in 1, 2, 3, 4, 5 {
        .color-gradient-category-#{$value} {
            &.bg {
                background-color: nb-theme(color-gradient-category-#{$value});
            }
            &.color {
                color: nb-theme(color-gradient-category-#{$value});
            }
            .icon {
                align-items: center;
                display: flex;
                justify-content: center;
                color: nb-theme(card-background-color);
                background-color: nb-theme(color-gradient-category-#{$value});

                svg {
                    height: 70%;
                    width: 70%;
                }
            }
        }
    }
    @each $value in 1, 2, 3, 4, 5 {
        .color-gradient-numeric-#{$value} .icon {
            align-items: center;
            display: flex;
            justify-content: center;
            color: nb-theme(card-background-color);
            background-color: nb-theme(color-gradient-numeric-#{$value});

            svg {
                height: 70%;
                width: 70%;
            }
        }
    }
    .chart-color-none .icon {
        background-color: nb-theme(color-gradient-category-empty);
    }

    .empty-icon {
        .icon {
            height: 20px;
            width: 20px;
        }
    }

    .zoom-selector,
    .time-button-container {
        .time-button {
            padding: 10px 15px;
            outline: transparent;
            border-radius: 0;
            margin-left: -1px;
            color: nb-theme(text-color-1) !important;
            border-color: nb-theme(background-basic-color-3) !important;
            background-color: nb-theme(background-basic-color-1) !important;

            &:hover {
                background-color: nb-theme(background-basic-color-3) !important;
            }
        }

        .phase-active {
            background-color: nb-theme(background-basic-color-3) !important;
        }

        .phase-active:focus,
        .phase-active:active,
        .time-button:active {
            outline: none;
            box-shadow: none;
        }

        button:first-child {
            border-radius: 5px 0 0 5px;
        }

        button:last-child {
            border-radius: 0 5px 5px 0;
        }
    }

    .value-input-container {
        width: 40%;
        display: flex;
        flex-direction: row;
        position: relative;

        .value-prefix {
            position: absolute;
            color: nb-theme(color-text-300);
            font-size: 11px;
            line-height: 16px;
            top: 6px;
            left: 10px;
        }

        .value-suffix {
            position: absolute;
            color: nb-theme(color-text-300);
            font-size: 11px;
            line-height: 16px;
            top: 6px;
            right: 10px;
        }

        .value-title-input {
            width: 100%;
            height: 28px;
            font-weight: bold;
            font-size: 0.6rem !important;
            padding-left: 20px;
            background-color: nb-theme(background-basic-color-1);
        }
    }

    .table-container {
        margin: 15px 24px 34px;
        padding: 0 !important;
        height: 100%;
        background-color: nb-theme(table-background-color);
        border: 1px solid nb-theme(border-basic-color-4);

        .table-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 16.5px;
            width: 100%;
        }
    }

    // filter styles

    .main-content-with-filter {
        display: flex;
        width: 100%;
        height: 100%;
    }
    .filter {
        position: fixed;
        right: 0;
        height: calc(100vh - 64px);
        border-left: 1px solid nb-theme(border-basic-color-3);
        z-index: 10;
    }
    .router-outlet-container {
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;
        width: 100%;
        min-width: 804px;
    }
    ::ng-deep .router-outlet-container > *:not(router-outlet) {
        height: 100%;
    }

    .filter-container {
        display: flex;
        height: 100%;
        align-items: flex-start;
        justify-content: space-between;
        width: 249px;
        background-color: nb-theme(background-basic-color-2);

        .filter-scrollable {
            width: 100%;
            height: calc(100vh - 64px);
            overflow-y: auto;
        }

        .filter-scrollable-with-footer {
            width: 100%;
            height: calc(100vh - 64px - 73px); // less: header, footer
            overflow-y: auto;
        }
    }

    .filter-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 65px;
        border-bottom: 1px solid nb-theme(border-basic-color-2);
        background-color: nb-theme(header-background-color);
        padding: 0 11px 0 15px;
        flex: 0 0 auto;
    }

    .ngx-file-drop__drop-zone--over {
        border: 2px dotted nb-theme(color-success-500) !important;
        background-color: nb-theme(color-basic-100) !important;
    }

    .drop-zone {
        width: 100%;
        background-color: nb-theme(background-basic-color-2);
        min-height: 120px;
        border: 2px dotted nb-theme(border-color-1);
        border-radius: 7px;
        display: inline-flex;
        place-content: center;
        align-items: center;
        color: nb-theme(text-color-3);

        &:hover {
            background-color: nb-theme(background-basic-color-5);
            border: 2px dotted nb-theme(color-secondary-default);
        }

        span {
            display: inline;
            margin: 0 5px;
            cursor: pointer;
            color: nb-theme(color-success-500);
        }
    }

    .col-form-label {
        text-align: right;
        font-family: Roboto, 'Helvetica Neue', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: nb-theme(color-basic-600);
        padding-right: 8px;
    }

    .col-form-data {
        text-align: right;
        font-family: Roboto, 'Helvetica Neue', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: nb-theme(text-basic-color);
        padding-right: 8px;
    }

    .action-cell {
        display: flex;
        flex-direction: row;
        padding: 0.25rem;
        justify-content: space-evenly;
    }

    .table-action-btn {
        background-color: nb-theme(table-action-btn-background-color);
        color: nb-theme(table-action-btn-color);
        border: none;
        padding: 12px;
        height: 2rem;
        width: 2rem;

        &:not(:last-of-type) {
            margin-right: 0.25rem;
        }

        nb-icon {
            color: nb-theme(table-action-btn-color);

            svg {
                height: 20px;
                width: 18px;
            }
        }

        &:hover {
            background-color: nb-theme(table-action-btn-background-color-hover);
            color: nb-theme(table-action-btn-color-hover);

            nb-icon {
                color: nb-theme(table-action-btn-color-hover);
            }
        }
    }

    @include screen-sm {
        .router-outlet-container {
            min-width: 0;
        }
        .page-container {
            min-width: 0;
        }
    }

    @include screen-md {
        .router-outlet-container {
            min-width: 0;
        }
        .page-container {
            min-width: 0;
        }
    }

    form.header-filters-form {
        align-items: center;
        display: flex;
        font-family: Roboto, 'Helvetica Neue', sans-serif;
        padding: 0 0 1rem;
        width: 100%;

        &.spb {
            justify-content: space-between;
        }

        .flex {
            align-items: center;
            display: flex;
        }

        label {
            color: nb-theme(color-text-300);
            font-weight: 500;
            font-size: 0.75rem !important;
            line-height: 1rem !important;
            margin: 0 1rem 0 0;

            &.disabled {
                color: nb-theme(color-text-200);
            }
        }

        nb-select {
            font-family: Roboto, 'Helvetica Neue', sans-serif;
            font-weight: 700;
            font-size: 0.75rem !important;
            margin-right: 1rem;
            width: 10rem;

            button.select-button {
                background-color: nb-theme(background-basic-color-5);
                min-width: 10rem;

                &:disabled {
                    color: nb-theme(color-text-200) !important;
                    border-color: nb-theme(input-basic-border-color) !important;
                }
            }
        }

        input {
            background-color: nb-theme(background-basic-color-5);

            &:disabled {
                border-color: nb-theme(input-basic-border-color);
            }
        }

        nb-radio-group {
            margin-right: 1rem;
        }

        .pageHeaderItem {
            font-family: Roboto, 'Helvetica Neue', sans-serif;
            font-size: 0.75rem !important;
            margin-right: 1rem;
            width: 10rem;

            nb-select {
                button.select-button {
                    background-color: nb-theme(background-basic-color-5);
                    font-size: 0.75rem !important;
                    min-width: 10rem;
                }
            }

            .input-suffix {
                align-items: center;
                display: flex;
                justify-content: center;
                background-color: nb-theme(background-basic-color-2);
                border-left: 1px solid nb-theme(border-color-2);
                border-radius: 0 0.25rem 0.25rem 0;
                color: nb-theme(color-text-300);
                width: calc(100% - 2px);
                height: calc(100% - 2px);

                &.disabled {
                    color: nb-theme(color-text-200);
                }
            }

            input {
                font-size: 0.75rem !important;

                &:disabled {
                    color: nb-theme(color-text-200);
                }
            }
        }

        ngx-numeric-range-control {
            margin-right: 1rem;
        }
    }

    .general-chart {
        @include chart-card-mixin;

        .chart-card-header {
            align-items: center;
            justify-content: space-between;
            flex-direction: row !important;
            padding: 1rem;

            ngx-chart-settings {
                ngx-icon-box {
                    position: static !important;
                }
            }
        }

        .chart-card-footer-legend {
            display: flex;
            padding: 1.5rem 0 1.5rem 1.5rem;

            .chart-footer-item {
                align-items: center;
                display: flex;
                margin-right: 1rem;

                .line {
                    height: 2px;
                    margin-right: 0.5rem;
                    width: 20px;
                }

                .line-dashed {
                    border-style: dashed;
                    border-width: 1px;
                    background-color: transparent !important;
                    margin-right: 0.5rem;
                    width: 20px;
                }

                .circle {
                    border-radius: 50%;
                    height: 12px;
                    margin-right: 0.5rem;
                    width: 12px;
                }

                .circle-outline {
                    border-style: solid;
                    border-width: 1px;
                    background-color: rgba(255, 96, 66, 0.06) !important;
                    border-radius: 50%;
                    height: 12px;
                    margin-right: 0.5rem;
                    width: 12px;
                }

                .footer-label {
                    color: nb-theme(color-text-300);
                    font-size: 0.75rem;
                    font-weight: 500;
                    line-height: 1rem;
                    margin: 0;
                    text-transform: capitalize;
                }
            }
        }
    }
}
