@import 'pages-common';

@mixin ngx-pace-theme() {
    .pace .pace-progress {
        background: nb-theme(color-primary-500);
    }

    .pace .pace-progress-inner {
        box-shadow: 0 0 10px nb-theme(color-primary-500), 0 0 5px nb-theme(color-primary-500);
    }

    .pace .pace-activity {
        display: none;
    }
}
