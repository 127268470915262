/*
 * This application is designed for desktop use.
 * - Default screen size is 1024px or larger (HD displays).
 * Default styling expects 1024px or larger displays, and media queries may be
 * used for specific smaller sizes as defined below.
 * In the context of this application:
 * - Small (sm): x <= 768px
 * - Medium (md): 769px < x <= 1024px
 * - Large (lg): 1025px < x <= 1440px
 * - If not specified, default styles assume > 1024px
 */

$screen-sm-max: 768px;
$screen-md-max: 1024px;
$screen-lg-max: 1440px;

/*
 * Select the specific screen size
 */
@mixin screen-sm {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}

@mixin screen-md {
    @media (min-width: #{$screen-sm-max + 1px}) and (max-width: #{$screen-md-max}) {
        @content;
    }
}

@mixin screen-lg {
    @media (min-width: #{$screen-md-max + 1px}) and (max-width: #{$screen-lg-max}) {
        @content;
    }
}

/*
 * Select up to the specified screen size
 */
@mixin screen-until-sm {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}

@mixin screen-until-md {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
}

@mixin screen-until-lg {
    @media (max-width: #{$screen-lg-max}) {
        @content;
    }
}

/*
 * Select custom screen size
 */
@mixin screen-custom($min, $max) {
    @media (min-width: #{$min + 'px'}) and (max-width: #{$max + 'px'}) {
        @content;
    }
}
