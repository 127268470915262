@import 'pages-common';

@mixin ngx-helpers-theme() {
    .horizontal-scroll-container {
        display: flex;
        margin: 0;
        overflow-x: scroll;

        > :not(:last-child) {
            margin-right: 1rem;
        }
    }
}
