//@import 'node_modules/bootstrap/dist/css/bootstrap.css';
//@import "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css";

.ngx-datatable {
    width: 98%;
    max-width: 98%;
    margin-bottom: 1rem;
    background-color: transparent;
    align-self: center;
}

.datatable-header {
    //@extend thead;

    color: #fff;
    background-color: #212529;
    border-color: #32383e;
    text-align: center;
    //padding: 0 10px 0;
}

.datatable-header-cell {
    //vertical-align: bottom;
    //border-bottom: 2px solid #dee2e6;

    border-color: #32383e;
    border-width: 1px;
    border-style: solid;
    vertical-align: center;
    padding: 6px;
    width: 50px;
}

.datatable-body-row {
    border-bottom-color: #eeeeee;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin: 5px;
    cursor: pointer;
}

.datatable-body-cell {
    width: 100px;
    border-bottom-color: #000;

    //display: table;
    //width: auto;
    //margin: 0px; /* adjust as needed */
    //padding: 0px; /* adjust as needed */
}

.table-cell {
    text-align: center;
}

.col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}

.col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}
