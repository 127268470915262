@import 'themes';
@import 'media.mixin.scss';

@mixin chart-card-body-mixin() {
    .chart-card-body {
        display: flex;
        flex-direction: column;
        overflow: unset;

        .chart-container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            align-self: center;
        }
    }
}

@mixin chart-card-footer-mixin {
    .chart-card-footer {
        display: flex;
        flex-wrap: wrap;
        min-height: 40px;
        align-items: center;
        padding: 0 0 0 15px;
        // background-color: nb-theme(background-basic-color-2);

        :not(:first-child).footer-label-title {
            margin-left: 15px;
        }

        .footer-label-title {
            display: flex;
            flex-wrap: wrap;
            font-size: 12px;
            font-weight: 600;
            align-items: center;
            margin-right: 10px;
            cursor: pointer;

            .color-point {
                display: inline-block;
                margin-top: 3px;
                margin-bottom: 3px;
                margin-right: 3px;
                border-radius: 5px;
                width: 20px;
                height: 20px;
                cursor: pointer;

                &.inactive {
                    background-color: #8992a3;
                }
            }

            .legend-label {
                display: inline-block;
                white-space: nowrap;
                font-size: 13px;
                cursor: pointer;
                margin-left: 8px;
                margin-bottom: 0;
            }
        }
    }
}

@mixin chart-card-mixin {
    flex: 1;
    border-radius: 4px;
    margin-bottom: 0;

    .chart-card-header {
        display: flex;
        flex-direction: column;
        overflow: unset;

        .chart-header {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            justify-content: flex-start;

            .chart-title {
                font-size: 18px;
                font-weight: bold;
                line-height: 24px;
                margin: 0;
            }

            .chart-subtitle {
                color: nb-theme(text-hint-color);
                font-size: 13px;
                font-weight: normal;
                line-height: 16px;
                margin: 0;
            }
        }
    }

    @include chart-card-body-mixin;
    @include chart-card-footer-mixin;
}

@mixin chart-card-container-mixin {
    display: flex;
    justify-content: space-between;
    height: 100%;
    position: relative;

    .chart-card {
        @include chart-card-mixin;
    }
}
